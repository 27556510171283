<template>
  <div>
    <ui-component-drawer
      v-if="showSideDrawer"
      :drawerTitle="drawerTitle"
      :showDrawer="showSideDrawer"
      :onClickClose="hideDrawer"
      :drawerSize="'extra-large'"
      position="right"
    >
      <template v-slot:content>
        <SpaceSummary v-if="showSpaceSummary" :spaceId="itemId" />
      </template>
    </ui-component-drawer>

    <ModalCreateSpace
      v-if="showModalCreateSpace"
      :showModal="showModalCreateSpace"
      :locationId="locationId"
      :onClickCancel="closeEditModal"
      @dataSaved="spaceCreated"
    />

    <ModalCombineSpaces
      v-if="showCombineSpaces"
      :showModal="showCombineSpaces"
      :locationId="locationId"
      :spaces="spacesPage.spaces"
      :onClickCancel="closeEditModal"
      @dataSaved="spacesCombined"
    />

    <ModalDeleteSpace
      v-if="showModalDeleteSpace"
      :showModal="showModalDeleteSpace"
      :space="spaceToDelete"
      :onClickCancel="closeEditModal"
      :isDeleting="isDeleting"
      @dataSaved="deleteSpace"
      :key="'mds' + timestamp"
    />

    <div>
      <div class="actionBar has-padding has-margin-bottom">
        <div class="level">
          <div class="level-left"></div>
          <div class="level-right">
            <a
              v-if="spaces.length > 1"
              @click="setShowCombineSpaces"
              class="has-margin-left"
            >
              <span class="icon">
                <font-awesome-icon :icon="['fas', 'link']" />
              </span>
              <span>Combine spaces</span>
            </a>

            <a @click="setShowCreateSpace">
              <span class="icon">
                <font-awesome-icon :icon="['fas', 'plus']" />
              </span>
              <span>Create space</span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="navbar-menu">
      <div class="navbar-start">
        <!-- navbar items -->
      </div>

      <div class="navbar-end">
        <div class="field has-addons">
          <input
            type="text"
            v-model="spacesPage.searchTerm"
            v-on:keyup.13="searchSpaces"
            class="input is-small"
            placeholder="Search spaces"
          />
          <button
            class="button is-primary is-small"
            :class="{ 'is-loading': isBusy }"
            @click="searchSpaces"
          >
            <i class="fas fa-search"></i>
          </button>
        </div>
      </div>
    </div>

    <div>
      <ui-loader v-if="isBusy" />

      <Message
        v-if="!isBusy && spaces.length === 0"
        :message="'No spaces created'"
      />

      <table
        v-if="spaces.length > 0"
        class="table is-fullwidth is-striped is-hoverable"
      >
        <thead>
          <tr>
            <!-- <th width="75">Number</th> -->
            <th>Space</th>
            <th width="100">Size</th>
            <th>Type</th>
            <th width="200">Configurations</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <transition-group
          name="staggered-fade"
          v-bind:css="false"
          v-on:before-enter="beforeEnter"
          v-on:enter="enter"
          tag="tbody"
        >
          <tr
            v-for="(space, index) in spaces"
            :key="'tr_' + index"
            :data-index="index"
          >
            <!-- <td class="has-text-right">{{ space.Number }}</td> -->
            <td>
              <a @click="goToSpace(space.Id)">{{ space.Name }}</a>
            </td>
            <td>{{ space.M2 }}m2</td>
            <td>
              <div class="field is-grouped is-grouped-multiline">
                <div
                  class="control"
                  v-for="meetingtype in space.Meetingtypes"
                  :key="meetingtype.Id"
                >
                  <div class="tags has-addons">
                    <span
                      class="tag has-margin-right"
                      :class="{
                        'is-link': meetingtype.IsActive,
                        'is-grey': !meetingtype.IsActive,
                      }"
                      >{{
                        meetingtype.MeetingtypeId | getMeetingtypeName
                      }}</span
                    >
                    <span class="tag is-grey">
                      <span
                        v-if="
                          getMeetingtypePublished(
                            space,
                            meetingtype.MeetingtypeId
                          )
                        "
                        class="icon"
                      >
                        <font-awesome-icon :icon="['fas', 'eye']" />
                      </span>

                      <span
                        v-if="
                          !getMeetingtypePublished(
                            space,
                            meetingtype.MeetingtypeId
                          )
                        "
                        class="icon"
                      >
                        <font-awesome-icon :icon="['fas', 'eye-slash']" />
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </td>
            <td>
              <span
                v-for="configuration in space.Settings"
                :key="configuration.SettingId"
              >
                <ui-svg-setting :settingId="configuration.SettingId" />
              </span>
            </td>
            <td class="has-text-right" width="75">
              <div class="field is-grouped has-text-right">
                <p class="control">
                  <a @click="setShowSpaceSummary(space)">
                    <span class="icon">
                      <font-awesome-icon :icon="['fas', 'info-circle']" />
                    </span>
                  </a>
                </p>

                <p class="control">
                  <a @click="goToSpace(space.Id)">
                    <span class="icon">
                      <font-awesome-icon :icon="['fas', 'edit']" />
                    </span>
                  </a>
                </p>

                <p class="control">
                  <a @click="setShowDeleteSpace(space)">
                    <span class="icon">
                      <font-awesome-icon
                        :icon="['fas', 'trash-alt']"
                        style="color:red"
                      />
                    </span>
                  </a>
                </p>
              </div>
            </td>
          </tr>
        </transition-group>
      </table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import spaceProvider from '@/providers/space'
import staggeredAnimation from '@/mixins/staggeredAnimation'

export default {
  components: {
    Message: () => import('@/components/UI/Message'),
    ModalCombineSpaces: () => import('@/components/Spaces/ModalCombineSpaces'),
    ModalCreateSpace: () => import('@/components/Spaces/ModalCreateSpace'),
    ModalDeleteSpace: () => import('@/components/Spaces/ModalDeleteSpace'),
    SpaceSummary: () => import('@/components/Spaces/SpaceSummary'),
  },

  mixins: [staggeredAnimation],

  data() {
    return {
      bottom: false,
      drawerTitle: '',
      hasLoaded: false,
      isDeleting: false,
      itemId: 0,
      locationId: Number(this.$route.params.locationId),
      meetingtypeId: 0,
      modalTitle: '',
      showCombineSpaces: false,
      showCreateSpace: false,
      showModal: false,
      showModalCreateSpace: false,
      showModalDeleteSpace: false,
      showSideDrawer: false,
      showSpaces: true,
      showSpaceSummary: false,
      showWarningsSummary: false,
      spaceToDelete: null,
      timestamp: new Date().getTime(),
    }
  },

  computed: {
    ...mapGetters('spaceStore', ['isBusy']),
    ...mapState('channelStore', ['channel']),
    ...mapState('spaceStore', ['spacesPage', 'spaces']),
  },

  created() {
    if (!this.isBusy && this.spaces.length === 0) {
      this.searchSpaces()
    }
  },

  methods: {
    ...mapMutations('spaceStore', ['setSpacesPage']),
    ...mapActions('spaceStore', ['saveSpacesPage', 'getLocationSpaces']),

    getMeetingtypePublished(space, meetingtypeId) {
      let m = space.Channels.find(
        (c) =>
          c.ChannelId === this.channel.Id && c.MeetingtypeId === meetingtypeId
      )
      if (m) {
        return m.IsPublic
      }

      return false
    },

    searchSpaces() {
      let self = this
      self.spacesPage.locationId = self.locationId
      self.spacesPage.page = 1
      self.spacesPage.reachedEnd = false

      this.saveSpacesPage(self.spacesPage)
    },

    spaceCreated() {
      let self = this

      self.searchSpaces()

      EventBus.$emit('showToast', {
        type: 'info',
        message: 'New space created!',
      })
    },

    deleteSpace(space) {
      let self = this

      self.isDeleting = true

      spaceProvider.methods
        .deleteSpaceById(space.Id)
        .then((response) => {
          let spaceIndex = self.spaces.findIndex((s) => s.Id === space.Id)
          if (spaceIndex > -1) {
            self.spaces.splice(spaceIndex, 1)
          }
        })
        .finally(() => {
          self.closeEditModal()
          self.isDeleting = false
        })
    },

    goToSpace(spaceId) {
      this.$router.push({
        name: `spaces-detail`,
        params: { locationId: this.locationId, spaceId: spaceId },
      })
    },

    spacesCombined(newSpace) {
      let self = this

      self.spaces.push(newSpace)
      EventBus.$emit('showToast', {
        type: 'info',
        message: 'New space created!',
      })
    },

    setShowCreateSpace() {
      this.showModal = true
      this.showModalCreateSpace = true
    },

    setShowCombineSpaces() {
      this.showModal = true
      this.showCombineSpaces = true
    },

    setShowDeleteSpace(space) {
      this.spaceToDelete = space
      this.showModal = true
      this.showModalDeleteSpace = true
    },

    closeEditModal() {
      this.showModal = false
      this.showModalCreateSpace = false
      this.showCombineSpaces = false
      this.showModalDeleteSpace = false

      this.modalTitle = ''
      this.spaceToDelete = null
    },

    hideModal() {
      this.drawerTitle = ''
      this.showSideDrawer = false
      this.showSpaceSummary = false
      this.itemId = 0
    },

    setShowSpaceSummary(space) {
      this.drawerTitle = 'Space detail'
      this.showSideDrawer = true
      this.showSpaceSummary = true
      this.itemId = space.Id
    },

    hideDrawer() {
      this.showSideDrawer = false
      this.showSpaceSummary = false

      this.itemId = space.Id
    },
  },
}
</script>
